.media-gallery {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--brand-color--faint);
}

.media-gallery__item {
  border: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &__icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .svg-icon {
      @apply h-24 w-24;
    }
  }

  &-overflow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 2;
    color: #333;
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

.media-gallery__item-thumbnail {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
  color: var(--primary-text-color--faint);
  line-height: 0;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.media-gallery__preview {
  @apply bg-gray-200 dark:bg-gray-900 rounded-lg;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  &--hidden {
    display: none;
  }
}

.media-gallery__gifv {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.media-gallery__item-gifv-thumbnail {
  @apply rounded-md;
  cursor: zoom-in;
  height: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
  z-index: 1;
  transform: none;
  top: 0;
}

.media-gallery__gifv__label,
.media-gallery__filename__label,
.media-gallery__file-extension__label {
  display: block;
  position: absolute;
  color: #fff;
  background: rgba($base-overlay-background, 0.5);
  bottom: 6px;
  left: 6px;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  z-index: 1;
  pointer-events: none;
  opacity: 0.9;
  transition: opacity 0.1s ease;
  line-height: 18px;
}

.media-gallery__gifv {
  &.autoplay {
    .media-gallery__gifv__label {
      display: none;
    }
  }

  &:hover {
    .media-gallery__gifv__label {
      opacity: 1;
    }
  }
}

$media-compact-size: 50px;

.media-gallery--compact {
  height: $media-compact-size !important;
  background: transparent;

  .media-gallery__item {
    width: $media-compact-size !important;
    height: $media-compact-size !important;
    inset: auto !important;
    float: left !important;
    margin-right: 5px;

    &-overflow {
      font-size: 20px;
    }

    &__icons .svg-icon {
      @apply h-8 w-8;
    }
  }

  .media-gallery__file-extension__label {
    display: none;
  }
}
